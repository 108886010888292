import React, { useState } from "react";
import Accordion from "./Accordian";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      title:
        "What is SaaS accounting software, and how does it differ from traditional accounting software?",
      answer:
        "SaaS accounting software is cloud-based and accessible through a web browser. It offers flexibility, real-time collaboration, and automatic updates compared to traditional, locally installed accounting software.",
    },
    {
      title: "What are the key features of Mocha accounting?",
      answer:
        "Mocha Accounting is tailored to meet the specific needs of small and medium-sized businesses. With user-friendly features, real-time data accessibility, and seamless automation, Mocha Accounting simplifies financial management, enhances accuracy, improves efficiency, and empowers businesses to unlock their full potential. Along with basic accounting features such as invoicing, expense tracking, financial reporting, and bank reconciliation, we also offer multi-user collaboration, ensuring comprehensive financial management.",
    },
    {
      title:
        "Is the software suitable for small businesses, enterprises, or both?",
      answer:
        "Mocha accounting software is designed to cater to the needs of both small businesses and enterprises, offering scalable solutions to accommodate varying business sizes. We understand each business is different, and your accounting system becomes more complicated as your business scales. Mocha accounting takes care of the complexity for you and lets you focus on building your business.",
    },
    {
      title:
        "How secure is my financial data with your SaaS accounting software?",
      answer:
        "Security is our top priority. We use advanced encryption protocols, secure servers, and regular backups to ensure the safety and integrity of your financial data.",
    },
    {
      title:
        "Is there a limit to the number of users or transactions I can have?",
      answer:
        "Depending on your subscription plan, we offer varying levels of users and transaction limits. We have plans suitable for businesses of all sizes. Visit our pricing page for more details.",
    },
    {
      title:
        "How frequently is the software updated, and do I need to pay extra for updates?",
      answer:
        "We provide regular updates to improve functionality, security, and features. Updates are included in your subscription, and you won't incur extra charges.",
    },
    {
      title: "Can I import data from my existing accounting software?",
      answer:
        "Yes, we offer data import functionalities to smooth the transition to our accounting software. Our support team is available to assist you through the process.",
    },
    {
      title: "What is the pricing structure for your accounting software?",
      answer:
        "Our pricing is tiered based on features and usage. We offer different plans to cater to the diverse needs of businesses. You can find detailed pricing information on our website.",
    },
  ];

  return (
    <div className="container mx-auto py-8 px-0 md:p-8 md:text-xl font-poppins">
      {/* <h1 className="text-4xl font-light mb-5 text-[#177245]">
        Frequently Asked Questions
      </h1> */}
      {faqData.map((faq, index) => (
        <Accordion
          key={index}
          title={faq.title}
          answer={faq.answer}
          isOpen={openIndex === index}
          onToggle={() => handleToggle(index)}
        />
      ))}
    </div>
  );
};

export default FAQ;
